<template>
	<div>
		<CRow>
			<CCol class="d-flex align-items-start">
				<form
					class="search-form flex-fill"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						data-test-id="search-input"
						placeholder="Search by username or name"
					>
						<template #prepend-content>
							<CIcon
								name="cil-magnifying-glass"
								data-test-id="search-button"
							/>
						</template>
					</CInput>
				</form>
				<BaseFilterButton
					class="ml-3"
					data-test-id="filter-button"
					:is-open="isShowFilter"
					:has-filter="hasFilter"
					@onClick="toggleFilter"
				/>
			</CCol>
		</CRow>

		<CRow>
			<CCol>
				<transition name="fade">
					<div
						v-if="isShowFilter"
						class="filter-box rounded-sm pt-3 px-3 mb-3 pb-3"
					>
						<CRow>
							<CCol md="3">
								<BaseInputDate
									:value="periodsDate"
									label="Period"
									placeholder="All periods"
									mode="range"
									@input="handleFilterDate"
								>
									<template #append-content>
										<CIcon class="cil-calendar" name="cil-calendar" />
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									v-model="filter.event"
									:options="getAuditLogEventOptions"
									:disabled="isLoadingFilterOption"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Event"
									@input="handleEventPeriod"
								/>
							</CCol>
							<CCol md="3">
								<div class="flex-fill">
									<label for="entity-id-filter-input">Entity ID</label>
									<div class="form-group no-number-step">
										<input
											id="entity-id-filter-input"
											:value="queryParams.entity_id"
											type="number"
											class="form-control entity-id-filter-input"
											placeholder="All entity IDs"
											@blur="handleEntityIDBlur"
											@keyup.enter="handleEntityIDBlur"
										>
									</div>
								</div>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									v-model="filter.entityType"
									:options="getAuditLogEntityOptions"
									:disabled="isLoadingFilterOption"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Entity"
									@input="handleEntities"
								/>
							</CCol>
							<CCol md="12">
								<CInputCheckbox
									:checked="!!queryParams.user_mark_order_as_paid"
									custom
									label="Show only &quot;Mark as Paid&quot; activity"
									@update:checked="handleMarkAsPaidChange"
								/>
							</CCol>
						</CRow>
					</div>
				</transition>
			</CCol>
		</CRow>

		<CRow class="mb-4">
			<CCol>
				<BaseSearchFormFooter
					:count="list.meta.total"
					append-text="list(s) found"
					@onReset="resetFilter"
				/>
			</CCol>
		</CRow>

		<CRow>
			<CCol>
				<BaseTable
					:is-loading="isLoading"
					:pagination="{
						pages: list.meta.lastPage,
						activePage: list.meta.currentPage,
					}"
					:fields="AUDIT_LOG_TABLE_FIELDS"
					:items="list.data"
					clickable-rows
					striped
					link-to="AuditLogDetail"
					vertical-align="top"
					class="table-custom"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{
									$t('global.searchNotFound', {
										field: 'audit logs',
									})
								}}
							</p>
						</div>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import BaseInputDate from '@/components/BaseInputDate.vue';
import { AUDIT_LOG_TABLE_FIELDS, AUDIT_LOG_MARK_AS_PAID_STATUS } from '../enums/auditLogs';
import {
	cleanObject,
	randomSearchString,
	hasSearchFilter,
	cloneDeep,
	getSelectedValueDropdown,
	getBooleanQueryParameter,
	dateFormat,
} from '../assets/js/helpers';

export default {
	name: 'AuditLogs',
	components: {
		BaseInputDate,
	},
	data() {
		return {
			AUDIT_LOG_TABLE_FIELDS,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				event: this.$route.query.event || null,
				entity_type: this.$route.query.entity_type || null,
				entity_id: this.$route.query.entity_id || null,
				start_at: this.$route.query.start_at || null,
				end_at: this.$route.query.end_at || null,
				user_mark_order_as_paid: getBooleanQueryParameter(this.$route.query.user_mark_order_as_paid),
				r: randomSearchString(),
			},
			filter: {
				// Filter option change and replace in mounted
				event: null,
				entityType: null,
			},
			isShowFilter: false,
			isLoadingFilterOption: false,
		};
	},
	computed: {
		...mapState('auditLogs', {
			list: 'list',
		}),
		...mapGetters({
			getAuditLogEventOptions: 'auditLogs/getAuditLogEventOptions',
			getAuditLogEntityOptions: 'auditLogs/getAuditLogEntityOptions',
		}),
		isLoading() {
			return this.list.isLoading;
		},
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
		periodsDate() {
			if (!this.queryParams.start_at || !this.queryParams.end_at) {
				return null;
			}

			return {
				start: new Date(this.queryParams.start_at),
				end: new Date(this.queryParams.end_at),
			};
		},
	},
	async mounted() {
		this.isShowFilter = this.hasFilter;
		this.isLoadingFilterOption = true;

		await this.getAuditLogs(this.queryParams);
		await this.getAuditLogOption();

		this.filter.event = getSelectedValueDropdown(this.$route.query.event, this.getAuditLogEventOptions);
		this.filter.entityType = getSelectedValueDropdown(this.$route.query.entity_type, this.getAuditLogEntityOptions);

		this.isLoadingFilterOption = false;
	},
	methods: {
		...mapActions({
			getAuditLogs: 'auditLogs/getAuditLogs',
			getAuditLogOption: 'auditLogs/getAuditLogOption',
		}),

		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},

		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},

		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},

		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},

		handleEventPeriod({ value }) {
			this.queryParams.page = null;
			this.queryParams.event = value;
			this.updateUrlParams();
		},

		handleEntities({ value }) {
			this.queryParams.page = null;
			this.queryParams.entity_type = value;
			this.updateUrlParams();
		},

		handleEntityIDBlur(e) {
			const value = e.target.value;
			if (this.queryParams.entity_id === value) {
				return; // Prevent reload page after lost focus input
			}

			this.queryParams.page = null;
			this.queryParams.entity_id = value;
			this.updateUrlParams();
		},

		handleFilterDate(value) {
			let start = null;
			let end = null;

			if (value) {
				start = dateFormat(value.start);
				end = dateFormat(value.end);
			}

			this.queryParams.page = null;
			this.queryParams.start_at = start;
			this.queryParams.end_at = end;
			this.updateUrlParams();
		},

		handleMarkAsPaidChange(value) {
			this.queryParams.page = null;
			this.queryParams.user_mark_order_as_paid = AUDIT_LOG_MARK_AS_PAID_STATUS[value];
			this.updateUrlParams();
		},

		resetFilter() {
			this.queryParams = {
				q: null,
				page: null,
				entity_id: null,
				entity_type: null,
				start_at: null,
				end_at: null,
				r: null,
			};

			this.updateUrlParams();
		},
	},
};
</script>

<style lang="scss" scoped>
	// Set placeholder of filter by date range
	::v-deep .form-control-date {
		input[type="input"] {
			@include placeholder-black;
		}
	}

	// Set placeholder of filter by entity id
	.entity-id-filter-input {
		@include placeholder-black;
	}

	.badge-status {
		min-width: rem(65);
	}

	.filter-box {
		background-color: $color-gray-100;
	}
</style>
