export const AUDIT_LOG_TABLE_FIELDS = Object.freeze([
	{ key: 'date', label: 'Date', class: 'th-mw-150' },
	{ key: 'userName', label: 'Username', class: 'th-mw-250' },
	{ key: 'fullName', label: 'Name', class: 'th-mw-100' },
	{ key: 'event', label: 'Event', class: 'th-mw-100' },
	{ key: 'entityId', label: 'Entity ID', class: 'th-mw-150' },
	{ key: 'entity', label: 'Entity', class: 'th-mw-150' },
]);

export const AUDIT_LOG_EVENT_STATUSES = {
	UPDATED: 'updated',
	CREATED: 'created',
	DELETED: 'deleted',
	RESTORED: 'restored',
};

export const AUDIT_LOG_MODEL_TYPE = {
	CATEGORY: 'category',
	ORDER: 'order',
	INCREMENT: 'increment',
	SHIPMENT: 'shipment',
	PAGE: 'page',
	PAYMENTMETHOD: 'paymentMethod',
	PROMOTION: 'promotion',
};

export const AUDIT_LOG_PERIODS_OPTIONS = [
	{ name: 'All events', value: null },
	{ name: 'Updated', value: AUDIT_LOG_EVENT_STATUSES.UPDATED },
	{ name: 'Created', value: AUDIT_LOG_EVENT_STATUSES.CREATED },
	{ name: 'Deleted', value: AUDIT_LOG_EVENT_STATUSES.DELETED },
	{ name: 'Restored', value: AUDIT_LOG_EVENT_STATUSES.RESTORED },
];

export const AUDIT_LOG_MODEL_TYPE_OPTIONS = [
	{ name: 'All entities', value: null },
	{ name: 'Category', value: AUDIT_LOG_MODEL_TYPE.CATEGORY },
	{ name: 'Order', value: AUDIT_LOG_MODEL_TYPE.ORDER },
	{ name: 'Increment', value: AUDIT_LOG_MODEL_TYPE.INCREMENT },
	{ name: 'Shipment', value: AUDIT_LOG_MODEL_TYPE.SHIPMENT },
	{ name: 'Page', value: AUDIT_LOG_MODEL_TYPE.PAGE },
	{ name: 'Payment Method', value: AUDIT_LOG_MODEL_TYPE.PAYMENTMETHOD },
	{ name: 'Promotion', value: AUDIT_LOG_MODEL_TYPE.PROMOTION },
];

export const AUDIT_LOG_MARK_AS_PAID_STATUS = {
	true: 1,
	false: 0,
};